import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import DataTable from 'components/DataTable';
import * as taskData from 'components/data/tasks/tasks';
import { AtlasGqlFilterOperator, useUpcomingTasksListQuery } from 'types/atlas-graphql';
import { TABLE_SCROLL_WIDTH_SMALL } from 'utils/constants';
import { getInitialSort } from 'utils/helpers';

import { useFeatures } from 'utils/features';
import { TABLE_FILTER_OPERATORS } from 'utils/constants';
import { TASK_TABLE_STORAGE_KEY } from 'components/DataTable/Views/helpers/tableStorageKeys';
import { usePermissions } from 'utils/usePermissions';
import { WORK_ORDER_COLLABORATOR } from 'utils/access-control/roles';
import { TASK_LEVEL_TYPES } from 'utils/constants';

import { useAccountContext } from 'utils/account/AccountContext';

// For Archived Tasks
import {
  ArchivedFilterToggleActionClientSide,
  EArchivedFilterStateOption,
} from 'horizon/routes/WorkOrders/SharedActions';

// For custom field columns
import {
  getTaskTypeFiltersFromTasks,
  reformatClientSideWorkContainersByName,
} from 'components/data/tasks/customFields';
import { useFilteredTaskFields } from 'utils/useFilteredTaskFields';

const TASK_LIMIT = 100;

const queryVars = {
  limit: TASK_LIMIT,
  sortBy: [{ key: 'dueDate', sort: 'ASC' }],
  filterBy: [
    {
      key: 'dueDate',
      values: [moment().add(1, 'month').format('YYYY-MM-DD')],
      operator: 'LESS_THAN_OR_EQUAL',
    },
    {
      key: 'status',
      values: ['IN_PROGRESS', 'PENDING'],
      operator: TABLE_FILTER_OPERATORS.EQUALS,
    },
  ],
};

const atlasTypeFilter = {
  key: 'type',
  operator: AtlasGqlFilterOperator.Equals,
  values: TASK_LEVEL_TYPES,
};

export default function UpcomingTasks() {
  const { hasReleaseToggle } = useAccountContext();
  const hasTaskTablesCampaignColumn = hasReleaseToggle('task-tables-campaign-column');

  const { WORK_ORDERS, HORIZON_DAMAGES } = useFeatures().features;

  const [archiveFilter, setArchiveFilter] = useState(EArchivedFilterStateOption.ACTIVE);
  const [archiveFilteredData, setArchiveFilteredData] = useState([]);
  const [typesFilter, setTypesFilter] = useState(TASK_LEVEL_TYPES);
  const [hasTypeFilterApplied, setHasTypeFilterApplied] = useState(false);

  const { loading, data = {} } = useUpcomingTasksListQuery({
    variables: {
      ...queryVars,
      filterBy: [...queryVars.filterBy, ...[atlasTypeFilter]],
      includeHorizonDamage: !!HORIZON_DAMAGES,
    },
  });

  // Get the fields that are configured for the specific task types we're filtering for.
  const {
    fieldColumns = [],
    updateFilteredTaskFieldsValues = () => {},
    onFiltersChange = () => {},
    queryLoading,
    workContainerFields,
  } = useFilteredTaskFields(setTypesFilter);

  const { HasRole } = usePermissions();
  const isWoCollaborator = HasRole({ role: WORK_ORDER_COLLABORATOR });

  const { tasks: taskResult } = data;
  const { items: tasks } = taskResult || {};
  const vendorOrgColumn = taskData.useTaskVendorOrgColumn();

  const taskColumns = [
    taskData.taskNumber,
    WORK_ORDERS ? taskData.atlasWorkOrder() : '',
    // When remove `rtv1:task-tables-campaign-column` remove the hasTaskTablesCampaignColumn check.
    WORK_ORDERS && hasTaskTablesCampaignColumn ? taskData.atlasCampaign : undefined,
    taskData.clientAtlasType,
    taskData.status,
    WORK_ORDERS ? taskData.shortDescription : undefined,
    // If we have horizon damages on we'll use that info. Otherwise use the old damages.
    HORIZON_DAMAGES ? taskData.atlasHorizonDamageLink : taskData.atlasDamageNumberLink,
    WORK_ORDERS
      ? { ...taskData.tags, defaultFilterOperator: TABLE_FILTER_OPERATORS.ARRAY_INCLUDES }
      : undefined,
    /* work order collaborators need to have the asset links scrubbed off so they don't click into
       asset details pages that they shouldn't have access to
    */
    WORK_ORDERS ? vendorOrgColumn : undefined,
    taskData.clientAtlasTaskSites(isWoCollaborator),
    taskData.clientAtlasTaskTurbines(isWoCollaborator),
    taskData.atlasTaskComponentClientSide(isWoCollaborator),
    taskData.dueDate,
    ...fieldColumns,
  ].filter(Boolean);

  const sortedFieldsData = useMemo(() => {
    // We need to sort additional fields by display order so that the order is consistent.
    // This allows logic in the column definitions to index into the array to resolve values and sort and filter
    if (archiveFilteredData) {
      // @ts-ignore - The Atlas types are expecting a lot of fields on AtlasGqlTask that aren't actually required
      // So we're not querying for them, and that makes the linter mad.
      return reformatClientSideWorkContainersByName(archiveFilteredData, workContainerFields);
    }
    // If for some reason we don't hit either if statement, return archiveFilteredData
    return archiveFilteredData;
  }, [archiveFilteredData, workContainerFields]);

  // Get the fields that are configured for the specific task types we're filtering for.
  useEffect(() => {
    if (!queryLoading) {
      updateFilteredTaskFieldsValues(typesFilter, false);
    }
    // Can't have the update function in the dependencies or it'll infinitely re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    typesFilter,
    queryLoading, // Need to re-run this useEffect if the query loads, so that we're processing the most up to date field definitions
  ]);

  useEffect(() => {
    let finalFilteredData = null;
    switch (archiveFilter) {
      case EArchivedFilterStateOption.ACTIVE: {
        finalFilteredData = tasks?.filter(t => !t.archived);
        break;
      }
      case EArchivedFilterStateOption.ARCHIVED: {
        finalFilteredData = tasks?.filter(t => t.archived);
        break;
      }
      // Default should only be BOTH case, but just for safety, have the fallback be all
      default: {
        finalFilteredData = tasks;
        break;
      }
    }
    setArchiveFilteredData(finalFilteredData);
  }, [archiveFilter, tasks]);

  useEffect(() => {
    const taskTypeFilters = getTaskTypeFiltersFromTasks(archiveFilteredData ?? tasks);
    // Don't override a type-filter set by the DataTable, but otherwise default to filtering for the given types
    if (taskTypeFilters !== typesFilter && !hasTypeFilterApplied) {
      setTypesFilter(taskTypeFilters);
    }
    // Can't have typesFilter as a dependency, would cause inifinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveFilteredData, tasks, archiveFilter]);

  return (
    <DataTable
      id="upcoming-task-list"
      title="Upcoming Tasks"
      showMultiSorter={true}
      showColumnPicker={true}
      columns={taskColumns}
      storageKey={TASK_TABLE_STORAGE_KEY}
      data={sortedFieldsData ?? tasks}
      loading={loading}
      additionalDropdownActions={[
        ArchivedFilterToggleActionClientSide(setArchiveFilter, archiveFilter),
      ]}
      pagination={{
        pageSize: 5,
      }}
      scrollWidth={TABLE_SCROLL_WIDTH_SMALL}
      initialPresetFilter={getInitialSort({ columnKey: 'dueDate', order: 'ascend' })}
      onFiltersChange={input => {
        setHasTypeFilterApplied(input?.type);
        onFiltersChange(input, sortedFieldsData ?? tasks);
      }}
    />
  );
}
