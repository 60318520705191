import { useCallback, useEffect, useMemo } from 'react';
import { doesUserHaveReleaseToggle } from '@skyspecs/release-utils-client';
import { useFeatures } from 'utils/features';
import { getDefaultStore, useAtom } from 'jotai';
import { RESET, useAtomCallback } from 'jotai/utils';
import { releaseTogglesDisabledAtom } from './account/atoms';
import { releaseTogglesAtom } from 'utils/account/atoms';

export const NEW_IMAGE_VIEWER = 'new_image_viewer';
export const DAMAGE_SORT_FILTER_IMPROVEMENTS = 'damage_sort_filter_improvements';
export const DAMAGE_PLOT = 'damage_plot';
export const NEW_TOOLTIP = 'new-tooltip';
export const WORK_CONTAINER_DELETE_COUNTS = 'work_container_delete_counts';
export const COST_MANAGEMENT = 'cost_management';
export const WORK_CONTAINER_DETAILS_TABLE_SIZE_100 = 'work_container_details_table_size_100';
export const REMOVE_PAPI_FULLSCREEN_IMAGE_QUERY = 'remove_papi_fullscreen_image_query';
export const SITE_DAMAGES_TABLE = 'site_damages_table';

/**
 * When an internal user has the `INTERNAL_TESTER` feature flag, they are able
 * to enable and disable their use of release toggles. By default, release
 * toggles will be enabled. When disabled, the entry `user` will be added to
 * local storage and that value will be read when making GraphQL requests. When
 * set, the header `X-SkySpecs-IgnoreToggles` will be sent with `user`.
 */
export function useDisableReleaseToggles() {
  const { INTERNAL_TESTER } = useFeatures().features;
  const [disabledReleaseToggles, setDisabledReleaseToggles] = useAtom(releaseTogglesDisabledAtom);

  const releaseTogglesDisabled = useMemo(() => {
    return disabledReleaseToggles.includes('user');
  }, [disabledReleaseToggles]);

  const toggleDisabledReleaseToggles = useAtomCallback(
    useCallback((get, set) => {
      const existing = get(releaseTogglesDisabledAtom);
      const newValue = existing.includes('user')
        ? existing.filter(item => item !== 'user')
        : [...existing, 'user'];

      set(releaseTogglesDisabledAtom, newValue.length === 0 ? RESET : newValue);

      // add a slight delay so the action is not so jarring
      setTimeout(() => {
        // reload the window after making changes to make sure the app shows the
        // appropriate state
        window.location.reload();
      }, 500);
    }, [])
  );

  useEffect(() => {
    if (INTERNAL_TESTER) {
      if (disabledReleaseToggles.length === 0) {
        setDisabledReleaseToggles(RESET);
      } else {
        setDisabledReleaseToggles(disabledReleaseToggles);
      }
    } else {
      setDisabledReleaseToggles(RESET);
    }
  }, [disabledReleaseToggles, INTERNAL_TESTER, setDisabledReleaseToggles]);

  return {
    canManageReleaseToggles: INTERNAL_TESTER,
    releaseTogglesDisabled,
    toggleDisabledReleaseToggles,
  };
}

// Whenever possible get release toggles via hasReleaseToggle from useAccountContext().
// This function reads the jotai store directly and is not updated after the initial load.
const jotaiStore = getDefaultStore();
export function hasReleaseToggle(releaseToggleName: string) {
  const releaseToggles: string[] = jotaiStore.get(releaseTogglesAtom);
  return doesUserHaveReleaseToggle({ releaseToggles }, releaseToggleName);
}
