import { useCallback } from 'react';
import { useEditWorkCampaignMutation } from 'types/atlas-graphql';
import { inlineEditable } from 'utils/editable/InlineEditable/InlineEditable';
import { isNull } from 'lodash';
import { useFeatures } from 'utils/features';
import * as workCampaignData from 'components/data/tasks/workCampaign';
import {
  serverAtlasType,
  atlasWorkOrder,
  tags,
  status,
  created,
  createdBy,
  dueDate,
  completedDate,
  taskVendorOrgHeaderColumn,
  useLegacyTaskVendorColumn,
} from 'components/data/tasks/tasks';
import {
  name as workOrderName,
  status as workOrderStatus,
  collaborators,
  workCampaign,
  dueDate as workOrderDueDate,
  workOrderVendorOrgHeaderColumn,
} from 'components/data/tasks/workOrders';
import {
  CAMPAIGN,
  WORK_ORDER,
  REPAIR_TASK,
  INSPECT_TASK,
  OTHER_TASK,
  INTERNAL_BLADE_INSPECTION_TASK,
  INSPECTION_TASK,
} from 'utils/constants';
import {
  useEditTaskMutation,
  useEditWorkOrderMutation,
  TaskDetailDocument,
} from 'types/atlas-graphql';
import { useApolloContext } from 'utils/apollo';
import { useAccountContext } from 'utils/account/AccountContext';

// This hook uses a combination of both rules and the isEditButtonVisible arg
// to determine whether to show the edit button.
// This is to continue to deprecate the use of rules going forward but need a way
// to dynamically show the button for ongoing work. - AN 9/27/23
export function useStandardFields(id = '', isEditButtonVisible = true) {
  const { papiClient } = useApolloContext();
  const [editTask] = useEditTaskMutation();
  const [editWorkCampaign] = useEditWorkCampaignMutation();
  const [editWorkOrder] = useEditWorkOrderMutation();
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const legacyTaskVendorColumn = useLegacyTaskVendorColumn();

  const { WORK_ORDERS, COLLABORATORS } = useFeatures().features;

  const onWOSave = useCallback(
    async input => {
      if (isNull(input)) return;
      await editWorkOrder({
        variables: {
          id,
          input,
        },
        refetchQueries: ['workOrder', 'WorkOrderDetail'],
      });
      // FIXME JD: Replace this resetStore call with a refetch once the Work Order Detail page hits Atlas.
      papiClient.resetStore();
    },
    [id, editWorkOrder, papiClient]
  );

  const onTaskSave = useCallback(
    async input => {
      if (isNull(input)) return;
      await editTask({
        variables: {
          id,
          input,
        },
        refetchQueries: [{ query: TaskDetailDocument, variables: { id } }],
      });
    },
    [id, editTask]
  );

  const onCampaignSave = useCallback(
    async input => {
      if (isNull(input)) return;
      await editWorkCampaign({
        variables: {
          id,
          input,
        },
      });
      // FIXME JD: Replace this resetStore call with a refetch once the Work Campaign Detail page hits Atlas.
      papiClient.resetStore();
    },
    [id, editWorkCampaign, papiClient]
  );

  const paidTaskFeatures = WORK_ORDERS
    ? {
        tags: inlineEditable(tags, { onSave: onTaskSave }, isEditButtonVisible),
        parent: inlineEditable(atlasWorkOrder(), { onSave: onTaskSave }, isEditButtonVisible),
      }
    : {};
  const paidWOFeatures = COLLABORATORS
    ? { collaborators: inlineEditable(collaborators, { onSave: onWOSave }, isEditButtonVisible) }
    : {};

  // customize the vendor to use VendorOrgChooser when release toggle is present
  const workOrderVendor = hasVendorRT
    ? {
        vendor_organization: inlineEditable(
          workOrderVendorOrgHeaderColumn,
          { onSave: onWOSave },
          isEditButtonVisible
        ),
      }
    : {};

  // when the release toggle is present, a custom message will display for the
  // new vendor column and the legacy vendor column is no longer editable
  const taskVendor = hasVendorRT
    ? { vendor_organization: taskVendorOrgHeaderColumn, vendor: legacyTaskVendorColumn }
    : {};

  const standardTaskFields = {
    task_type: serverAtlasType,
    status: inlineEditable(status, { onSave: onTaskSave }, isEditButtonVisible),
    created: created,
    created_by: createdBy,
    due_date: inlineEditable(dueDate, { onSave: onTaskSave }, isEditButtonVisible),
    completed_date: inlineEditable(completedDate, { onSave: onTaskSave }, isEditButtonVisible),
    ...taskVendor,
    ...paidTaskFeatures,
  };

  return {
    [CAMPAIGN]: {
      name: inlineEditable(workCampaignData.name, { onSave: onCampaignSave }, isEditButtonVisible),
      status: inlineEditable(
        workCampaignData.status,
        { onSave: onCampaignSave },
        isEditButtonVisible
      ),
      created: workCampaignData.created,
      created_by: workCampaignData.createdBy,
      due_date: inlineEditable(
        workCampaignData.dueDate,
        { onSave: onCampaignSave },
        isEditButtonVisible
      ),
    },
    [WORK_ORDER]: {
      name: inlineEditable(workOrderName, { onSave: onWOSave }, isEditButtonVisible),
      status: inlineEditable(
        workOrderStatus,
        { onSave: onWOSave, id: 'work-order-status-edit' },
        isEditButtonVisible
      ),
      created: workCampaignData.created, // fine to reuse, not editable
      created_by: workCampaignData.createdBy, // fine to reuse, not editable
      parent: inlineEditable(workCampaign, { onSave: onWOSave }, isEditButtonVisible),
      due_date: inlineEditable(
        workOrderDueDate,
        { onSave: onWOSave, id: 'work-order-due-date-edit' },
        isEditButtonVisible
      ),
      ...workOrderVendor,
      ...paidWOFeatures,
    },
    [REPAIR_TASK]: standardTaskFields,
    [INSPECT_TASK]: standardTaskFields,
    [OTHER_TASK]: standardTaskFields,
    [INTERNAL_BLADE_INSPECTION_TASK]: standardTaskFields,
    [INSPECTION_TASK]: standardTaskFields,
  };
}
