import { useEffect, useMemo } from 'react';
import { message } from 'antd5';
import {
  AtlasGqlOrganizationRelationshipScope,
  useGetOrganizationRelationshipsQuery,
  useGetTrustedOrganizationsQuery,
} from 'types/atlas-graphql';
import { useAccountContext } from 'utils/account/AccountContext';
import { notEmpty } from 'utils/types';

interface UseOrganizationRelationshipsArgs {
  scope?: AtlasGqlOrganizationRelationshipScope;
  skip?: boolean;
  types?: ('vendor' | 'delivery')[];
}

export function useOrganizationRelationships({
  scope = AtlasGqlOrganizationRelationshipScope.RelationshipOwner,
  skip,
  types,
}: UseOrganizationRelationshipsArgs = {}) {
  const { data, error, loading } = useGetOrganizationRelationshipsQuery({
    skip,
    variables: { scope, types },
  });

  const relationships = data?.organizationRelationships?.records;
  let errors = data?.organizationRelationships?.errors;

  if (!errors && error) {
    errors = [{ message: error.message, __typename: 'UserInputError' }];
  }

  if (loading) {
    return { loading, relationships: [] };
  }

  return { loading, errors, relationships };
}

interface UseOrganizationVendorRelationshipsArgs {
  skip?: boolean;
}

export function useOrganizationVendorRelationships({
  skip,
}: UseOrganizationVendorRelationshipsArgs = {}) {
  const { errors, loading, relationships } = useOrganizationRelationships({
    skip,
    scope: AtlasGqlOrganizationRelationshipScope.RelationshipOwner,
    types: ['vendor'],
  });

  const vendors = relationships?.map(r => r.relationshipPartner).filter(notEmpty) ?? [];
  const errorsString = errors?.map(e => e.message).join('. ');

  return { errors, errorsString, loading, vendors };
}

interface UseVendorOrgOptionsArgs {
  valueKey?: 'id' | 'name';
}

export function useVendorOrgOptions({ valueKey = 'id' }: UseVendorOrgOptionsArgs = {}) {
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const { errorsString, loading, vendors } = useOrganizationVendorRelationships({
    skip: !hasVendorRT,
  });

  useEffect(() => {
    if (!loading && errorsString) {
      message.warning(`Unable to load vendors. ${errorsString}`, 8);
    }
  }, [errorsString, loading]);

  const vendorOptions = useMemo(
    () =>
      vendors.map(v => ({
        label: v.name,
        text: v.name,
        value: valueKey === 'name' ? v.name : v.id,
      })),
    [valueKey, vendors]
  );

  return { loading, vendorOptions };
}

interface UseOrganizationDeliveryRelationshipsArgs {
  skip?: boolean;
}

/**
 * Gets a list of organizations that this organization is allowed to deliver to.
 * @param skip - Whether to skip the query.
 */
export function useOrganizationDeliveryCustomers({
  skip,
}: UseOrganizationDeliveryRelationshipsArgs = {}) {
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const { errors, loading, relationships } = useOrganizationRelationships({
    skip: skip || !hasVendorRT,
    scope: AtlasGqlOrganizationRelationshipScope.RelationshipPartner,
    types: ['delivery'],
  });
  const {
    error: errorsTrustedOrg,
    loading: loadingTrustedOrg,
    data: trustedOrgData,
  } = useGetTrustedOrganizationsQuery({
    skip: skip || hasVendorRT,
  });

  return useMemo(() => {
    if (!hasVendorRT) {
      return {
        loading: loadingTrustedOrg,
        errors: errorsTrustedOrg,
        errorsString: 'Error loading delivery customers',
        deliveryCustomers: trustedOrgData?.trustedOrganizations?.filter(notEmpty) ?? [],
      };
    }
    return {
      loading,
      errors,
      errorsString: errors?.map(e => e.message).join('. '),
      deliveryCustomers: relationships?.map(r => r.relationshipOwner).filter(notEmpty) ?? [],
    };
  }, [errors, loading, loadingTrustedOrg, relationships, errorsTrustedOrg, trustedOrgData]);
}

interface UseDeliveryOrgOptionsArgs {
  valueKey?: 'id' | 'name';
  skip?: boolean;
  sort?: boolean;
}

export function useDeliveryCustomerOrgOptions({
  valueKey = 'id',
  skip,
  sort,
}: UseDeliveryOrgOptionsArgs = {}) {
  const { errorsString, loading, deliveryCustomers } = useOrganizationDeliveryCustomers({
    skip,
  });

  useEffect(() => {
    if (!loading && errorsString) {
      message.warning(`Unable to load delivery organizations. ${errorsString}`, 8);
    }
  }, [errorsString, loading]);

  const deliveryCustomerOptions = useMemo(() => {
    const temp = deliveryCustomers.map(d => ({
      label: d.name,
      text: d.name,
      value: valueKey === 'name' ? d.name : d.id,
    }));

    if (sort) {
      temp.sort((a, b) => a.label.localeCompare(b.label));
    }

    return temp;
  }, [deliveryCustomers, valueKey]);

  return { loading, deliveryCustomerOptions };
}
