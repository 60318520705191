import { Select } from 'antd5';
import {
  AssetFilterInnerContainer,
  AssetSelectContainer,
  ComponentFiltersCollapse,
} from './AssetFilter.style';
import { CustomFilterPopover } from 'components/data/helpers/filters.style';
import { CustomFilterDropdownProps } from 'horizon/types/TableColumnDef';
import {
  AtlasGqlAsset,
  AtlasGqlAssetFieldValueSelect,
  AtlasGqlAssetType,
  AtlasGqlFilterOperator,
  useGetAssetsForDamageAssetFilterQuery,
  useGetMakeModelOptionsLazyQuery,
  useGetTypesForAssetFilterQuery,
} from 'types/atlas-graphql';
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { BaseSelectRef } from 'rc-select';
import { flatten, isEqual, snakeCase, uniqBy } from 'lodash';
import { Button, Tooltip, TooltipWithQuestionIcon } from 'components/ui';
import { getAssetColumnCopy } from 'components/data/assets/assetColumnRenderers';
import { naturalSortBy } from 'utils/naturalSort';
import { None } from 'components/data/helpers';
import { AutoFocus } from 'horizon/components/AutoFocus';
import { DataTableContext } from 'components/DataTable';

/**
 * selectedSiteIds - the list of site ids selected in the site column; when provided,
 *                   filtering by asset is enabled
 * lockedAssetTypes - the list of asset types pre-selected due to being on the asset
 *                    details page
 * lockedAssetIds - the list of asset ids pre-selected due to being on the asset
 *                  details page
 *
 * When lockedAssetTypes or lockedAssetIds are provided, the asset section will be
 * automatically populated and disabled, allowing for further filtering by component,
 * where applicable (e.g. if on the asset details page for a turbine, the turbine type
 * and specific turbine will be selected, and the user can filter by child component)
 */
type AssetFilterProps = CustomFilterDropdownProps & {
  tableId: string;
  lockedAssetTypes?: AtlasGqlAssetType[];
  lockedAssetIds?: string[];
};

type AssetFilterState = {
  assetTypes?: AtlasGqlAssetType[];
  assetMakes?: string[];
  assetModels?: string[];
  assets?: AtlasGqlAsset[];
  componentTypes?: AtlasGqlAssetType[];
  componentMakes?: string[];
  componentModels?: string[];
  components?: AtlasGqlAsset[];
};

const assetFilterStateReducer = (
  prevState: AssetFilterState,
  incomingState: AssetFilterState
): AssetFilterState => ({
  ...prevState,
  ...incomingState,
});

const GraphqlToStateKeyMap = {
  assetType: 'assetTypes',
  assetMake: 'assetMakes',
  assetModel: 'assetModels',
  assetId: 'assets',
  componentType: 'componentTypes',
  componentMake: 'componentMakes',
  componentModel: 'componentModels',
  componentId: 'components',
};

const NONE_OPTION = { label: <None />, value: '' };

const PAGE_SIZE = 100;

export const AssetFilter: React.FunctionComponent<AssetFilterProps> = ({
  tableId,
  lockedAssetTypes,
  lockedAssetIds,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const inputRef = useRef<BaseSelectRef>(null);
  const [filterState, dispatchFilterState] = useReducer<
    (prevState: AssetFilterState, incomingState: AssetFilterState) => AssetFilterState
  >(assetFilterStateReducer, {});
  const {
    assetTypes,
    assetMakes,
    assetModels,
    assets,
    componentTypes,
    componentMakes,
    componentModels,
    components,
  } = filterState;
  const [persistedStateRestored, setPersistedStateRestored] = useState<boolean>(false);

  const { filteredInfo } = useContext(DataTableContext);
  const selectedSiteIds: string[] = filteredInfo?.site ?? [];
  const sitesSelected = !!selectedSiteIds.length;

  const { data: assetTypesData, loading: assetTypesLoading } = useGetTypesForAssetFilterQuery();
  const [
    getAssetMakeModelOptions,
    { data: assetMakeModelData, loading: assetMakeModelDataLoading },
  ] = useGetMakeModelOptionsLazyQuery();
  const [
    getComponentMakeModelOptions,
    { data: componentMakeModelData, loading: componentMakeModelDataLoading },
  ] = useGetMakeModelOptionsLazyQuery();

  const {
    data: assetsData,
    loading: assetsLoading,
    fetchMore: fetchMoreAssets,
    previousData: previousAssetsData,
  } = useGetAssetsForDamageAssetFilterQuery({
    variables: {
      filterBy: [
        ...(lockedAssetIds?.length
          ? [
              {
                key: 'assetIds',
                values: lockedAssetIds,
                operator: AtlasGqlFilterOperator.Equals,
              },
            ]
          : [
              {
                key: 'descendantOfId',
                values: selectedSiteIds,
              },
            ]),
        ...(assetTypes?.length
          ? [
              {
                key: 'assetTypeIds',
                values: assetTypes.map(({ id }) => id),
              },
            ]
          : []),
      ],
      limit: PAGE_SIZE,
    },
    notifyOnNetworkStatusChange: true,
    skip: (!sitesSelected || !assetTypes?.length) && !lockedAssetIds?.length,
  });

  const { data: componentsData, loading: componentsLoading } =
    useGetAssetsForDamageAssetFilterQuery({
      variables: {
        filterBy: [
          {
            key: 'descendantOfId',
            values: assets?.map(({ id }) => id) ?? [],
          },
        ],
      },
      skip: !assets?.length,
    });

  /**
   * Automatically fetch assets in pages of 100. The next page will automatically be
   * fetched until all available assets have been returned. Once the first page has
   * loaded, the asset dropdown will be interactive.
   */
  useEffect(() => {
    if (
      assetsData &&
      assetsData?.assetsWithMetadata.items.length !==
        previousAssetsData?.assetsWithMetadata.items.length
    ) {
      fetchMoreAssets({
        variables: {
          offset: assetsData.assetsWithMetadata.items.length,
        },
        updateQuery: (previousData, { fetchMoreResult }) => ({
          assetsWithMetadata: {
            items: [
              ...previousData.assetsWithMetadata.items,
              ...fetchMoreResult.assetsWithMetadata.items,
            ],
          },
        }),
      });
    }
  }, [assetsData, previousAssetsData]);

  /**
   * Fetch make/model data when asset/component type is selected. This can be skipped if
   * site is selected, since the make/model data can be pulled from the returned assets.
   */
  useEffect(() => {
    if (!sitesSelected && assetTypes?.length === 1) {
      if (assetTypes[0].name === 'Turbine') {
        getAssetMakeModelOptions({ variables: { internalName: 'turbine_make' } });
      } else if (assetTypes[0].name === 'Blade') {
        getAssetMakeModelOptions({ variables: { internalName: 'blade_make' } });
      }
    }
  }, [assetTypes]);
  useEffect(() => {
    if (!sitesSelected && componentTypes?.length === 1) {
      if (componentTypes[0].name === 'Blade') {
        getComponentMakeModelOptions({ variables: { internalName: 'blade_make' } });
      }
    }
  }, [componentTypes]);

  /**
   * Memoized sets of options for asset fields
   */
  const allAssets: AtlasGqlAsset[] = useMemo(
    () => (assetsData?.assetsWithMetadata.items ?? []) as AtlasGqlAsset[],
    [assetsData]
  );
  const assetTypeOptions: AtlasGqlAssetType[] = useMemo(
    () => (assetTypesData?.assetTypes ?? []).filter(({ name }) => name !== 'Site'),
    [assetTypesData]
  );
  const assetMakeOptions = useMemo(() => {
    if (assetTypes?.length !== 1) {
      return [];
    }

    if (allAssets.length) {
      return [
        NONE_OPTION,
        ...uniqBy(
          allAssets
            .filter(a => assetTypes.some(({ id }) => id === a.assetType?.id))
            .map(a => {
              const makeField: AtlasGqlAssetFieldValueSelect | null | undefined =
                a.fieldValues?.find(v => v?.fieldDefinition.name === 'Make');
              return makeField?.selectValue ? { value: makeField.selectValue } : undefined;
            })
            .filter((v): v is { value: string } => !!v),
          'value'
        ),
      ];
    }

    if (assetMakeModelData) {
      return [
        NONE_OPTION,
        ...uniqBy(
          assetMakeModelData.assetFieldDefinition.independentCombinationOptions?.map(
            ({ independentFieldValue }) => ({ value: independentFieldValue })
          ),
          'value'
        ),
      ];
    }
  }, [allAssets, assetTypes, assetMakeModelData]);
  const assetModelOptions = useMemo(() => {
    if (assetTypes?.length !== 1 || !assetMakes?.length) {
      return [];
    }

    if (allAssets.length) {
      return [
        NONE_OPTION,
        ...uniqBy(
          allAssets
            .filter(a => assetTypes.some(({ id }) => id === a.assetType?.id))
            .map(a => {
              const makeField: AtlasGqlAssetFieldValueSelect | null | undefined =
                a.fieldValues?.find(v => v?.fieldDefinition.name === 'Make');

              if (!makeField?.selectValue || !assetMakes.includes(makeField.selectValue)) {
                return undefined;
              }

              const modelField: AtlasGqlAssetFieldValueSelect | null | undefined =
                a.fieldValues?.find(v => v?.fieldDefinition.name === 'Model');

              return modelField?.selectValue ? { value: modelField.selectValue } : undefined;
            })
            .filter((v): v is { value: string } => !!v),
          'value'
        ),
      ];
    }

    if (assetMakeModelData) {
      return [
        NONE_OPTION,
        ...uniqBy(
          flatten(
            assetMakeModelData.assetFieldDefinition.independentCombinationOptions
              ?.filter(
                ({ independentFieldValue }) =>
                  independentFieldValue && assetMakes.includes(independentFieldValue)
              )
              ?.map(({ dependentFieldOptions }) => dependentFieldOptions?.map(o => ({ value: o })))
          ).filter((o): o is { value: string } => !!o),
          'value'
        ),
      ];
    }
  }, [allAssets, assetTypes, assetMakes, assetMakeModelData]);
  const assetOptions: (AtlasGqlAsset & { displayName: string })[] = useMemo(() => {
    return allAssets
      .filter(a => {
        // filter out assets that don't match selected make(s)
        if (assetMakes?.length) {
          const makeField: AtlasGqlAssetFieldValueSelect | null | undefined = a.fieldValues?.find(
            v => v?.fieldDefinition.name === 'Make'
          );

          if (!makeField || !assetMakes.some(m => m === makeField.selectValue)) {
            return false;
          }
        }

        // filter out assets that don't match selected model(s)
        if (assetModels?.length) {
          const modelField: AtlasGqlAssetFieldValueSelect | null | undefined = a.fieldValues?.find(
            v => v?.fieldDefinition.name === 'Model'
          );

          if (!modelField || !assetModels.some(m => m === modelField.selectValue)) {
            return false;
          }
        }

        return true;
      })
      .map(asset => ({
        ...asset,
        displayName: getAssetColumnCopy({
          asset,
          includeSite: selectedSiteIds.length > 1,
          separatorText: ' | ',
        }),
      }))
      .sort(naturalSortBy('displayName'));
  }, [allAssets, assetTypes, assetMakes, assetModels]);

  /**
   * If the set of possible assets changes due to other filters, remove any previously
   * selected assets that are no longer available
   */
  useEffect(() => {
    const remainingAssets = assets?.filter(({ id }) => assetOptions.some(o => o.id === id));
    if (remainingAssets?.length !== assets?.length) {
      dispatchFilterState({ assets: remainingAssets?.length ? remainingAssets : undefined });
    }
  }, [assetOptions]);

  /**
   * Memoized sets of options for component fields
   */
  const allComponents: AtlasGqlAsset[] = useMemo(
    () => componentsData?.assetsWithMetadata.items.filter((a): a is AtlasGqlAsset => !!a) ?? [],
    [componentsData]
  );
  const componentTypeOptions: AtlasGqlAssetType[] = useMemo(
    () =>
      assetTypes?.some(({ name }) => name === 'Turbine')
        ? (assetTypesData?.assetTypes ?? []).filter(
            ({ name }) => !['Site', 'Turbine'].includes(name)
          )
        : [],
    [assetTypesData, assetTypes]
  );
  const componentMakeOptions = useMemo(() => {
    if (componentTypes?.length !== 1) {
      return [];
    }

    if (allComponents.length) {
      return [
        NONE_OPTION,
        ...uniqBy(
          allComponents
            .filter(a => componentTypes.some(({ id }) => id === a.assetType?.id))
            .map(a => {
              const makeField: AtlasGqlAssetFieldValueSelect | null | undefined =
                a.fieldValues?.find(v => v?.fieldDefinition.name === 'Make');
              return makeField?.selectValue ? { value: makeField.selectValue } : undefined;
            })
            .filter((v): v is { value: string } => !!v),
          'value'
        ),
      ];
    }

    if (componentMakeModelData) {
      return [
        NONE_OPTION,
        ...uniqBy(
          componentMakeModelData.assetFieldDefinition.independentCombinationOptions?.map(
            ({ independentFieldValue }) => ({ value: independentFieldValue })
          ),
          'value'
        ),
      ];
    }
  }, [allComponents, componentTypes, componentMakeModelData]);
  const componentModelOptions = useMemo(() => {
    if (componentTypes?.length !== 1 || !componentMakes?.length) {
      return [];
    }

    if (allComponents.length) {
      return [
        NONE_OPTION,
        ...uniqBy(
          allComponents
            .filter(a => componentTypes.some(({ id }) => id === a.assetType?.id))
            .map(a => {
              const makeField: AtlasGqlAssetFieldValueSelect | null | undefined =
                a.fieldValues?.find(v => v?.fieldDefinition.name === 'Make');

              if (!makeField?.selectValue || !componentMakes.includes(makeField.selectValue)) {
                return undefined;
              }

              const modelField: AtlasGqlAssetFieldValueSelect | null | undefined =
                a.fieldValues?.find(v => v?.fieldDefinition.name === 'Model');

              return modelField?.selectValue ? { value: modelField.selectValue } : undefined;
            })
            .filter((v): v is { value: string } => !!v),
          'value'
        ),
      ];
    }

    if (componentMakeModelData) {
      return [
        NONE_OPTION,
        ...uniqBy(
          flatten(
            componentMakeModelData.assetFieldDefinition.independentCombinationOptions
              ?.filter(
                ({ independentFieldValue }) =>
                  independentFieldValue && componentMakes.includes(independentFieldValue)
              )
              ?.map(({ dependentFieldOptions }) => dependentFieldOptions?.map(o => ({ value: o })))
          ).filter((o): o is { value: string } => !!o),
          'value'
        ),
      ];
    }
  }, [allComponents, componentTypes, componentMakes, componentMakeModelData]);
  const componentOptions: (AtlasGqlAsset & { displayName: string })[] = useMemo(() => {
    return allComponents
      .filter(a => {
        // filter out assets that don't match selected type(s)
        if (componentTypes?.length && !componentTypes.some(t => t?.id === a.assetType?.id)) {
          return false;
        }

        // filter out assets that don't match selected make(s)
        if (componentMakes?.length) {
          const makeField: AtlasGqlAssetFieldValueSelect | null | undefined = a.fieldValues?.find(
            v => v?.fieldDefinition.name === 'Make'
          );

          if (!makeField || !componentMakes.some(m => m === makeField.selectValue)) {
            return false;
          }
        }

        // filter out assets that don't match selected model(s)
        if (componentModels?.length) {
          const modelField: AtlasGqlAssetFieldValueSelect | null | undefined = a.fieldValues?.find(
            v => v?.fieldDefinition.name === 'Model'
          );

          if (!modelField || !componentModels.some(m => m === modelField.selectValue)) {
            return false;
          }
        }

        return true;
      })
      .map(asset => ({
        ...asset,
        displayName: getAssetColumnCopy({
          asset,
          includeSite: selectedSiteIds.length > 1,
          separatorText: ' | ',
        }),
      }))
      .sort(naturalSortBy('displayName'));
  }, [allComponents, componentTypes, componentMakes, componentModels]);

  /**
   * If the set of possible components changes due to other filters, remove any previously
   * selected components that are no longer available
   */
  useEffect(() => {
    const remainingComponents = components?.filter(({ id }) =>
      componentOptions.some(o => o.id === id)
    );
    if (remainingComponents?.length !== components?.length) {
      dispatchFilterState({
        components: remainingComponents?.length ? remainingComponents : undefined,
      });
    }
  }, [componentOptions]);

  /**
   * Restore persisted state from localStorage and pre-populate locked asset types/ids (e.g. on the
   * asset details page damage table).
   * These values will already have been passed in the graphql query, but we want to restore the
   * state here so it can be visualized and updated.
   *
   * Since the fields are interdependent and reliant on different data being returned from the API,
   * there is a separate useEffect for each field (other than make/model, which can be grouped).
   */

  // Will be true when all values from persisted state have been reflected in the filter component
  useEffect(() => {
    const requiredKeyPairs = selectedKeys.map(({ key }) => [
      key,
      GraphqlToStateKeyMap[key as keyof typeof GraphqlToStateKeyMap],
    ]);
    if (lockedAssetTypes?.length) {
      requiredKeyPairs.push(['assetType', GraphqlToStateKeyMap['assetType']]);
    }
    if (lockedAssetIds?.length) {
      requiredKeyPairs.push(['assetId', GraphqlToStateKeyMap['assetId']]);
    }
    const restored = requiredKeyPairs.every(
      ([graphqlKey, stateKey]) =>
        stateKey in filterState &&
        filterState[stateKey as keyof AssetFilterState]?.length ===
          selectedKeys.find(({ key }) => key === graphqlKey)?.value?.length
    );
    if (restored) {
      setPersistedStateRestored(true);
    }
  }, [selectedKeys, filterState, lockedAssetIds, lockedAssetTypes]);

  useEffect(() => {
    setPersistedStateRestored(false);
  }, [selectedSiteIds]);

  const getPersistedFilter = useCallback(
    (key: string): string[] | undefined =>
      selectedKeys.find(filter => filter.key === key)?.value as string[] | undefined,
    [selectedKeys]
  );

  // Restore saved asset type(s)
  useEffect(() => {
    if (
      !persistedStateRestored &&
      (selectedKeys.length || lockedAssetTypes?.length) &&
      assetTypeOptions.length
    ) {
      const selectedAssetTypes = getPersistedFilter('assetType');
      if (selectedAssetTypes) {
        dispatchFilterState({
          assetTypes: assetTypeOptions.filter(({ name }) =>
            selectedAssetTypes.some(t => t === snakeCase(name.toLowerCase()))
          ),
        });
      } else if (lockedAssetTypes?.length) {
        dispatchFilterState({
          assetTypes: assetTypeOptions.filter(({ id }) => lockedAssetTypes.some(t => t.id === id)),
        });
      }
    }
  }, [persistedStateRestored, selectedKeys, assetTypeOptions, lockedAssetTypes]);

  // Restore saved asset make(s)/model(s)
  useEffect(() => {
    if (!persistedStateRestored && selectedKeys.length && assetTypes?.length) {
      const filterState: AssetFilterState = {};
      const selectedAssetMakes = getPersistedFilter('assetMake');
      if (selectedAssetMakes) {
        filterState.assetMakes = selectedAssetMakes;
        const selectedAssetModels = getPersistedFilter('assetModel');
        if (selectedAssetModels) {
          filterState.assetModels = selectedAssetModels;
        }
        dispatchFilterState(filterState);
      }
    }
  }, [persistedStateRestored, selectedKeys, assetMakeOptions, assetModelOptions]);

  // Restore saved asset(s)
  useEffect(() => {
    if (
      !persistedStateRestored &&
      (selectedKeys.length || lockedAssetIds?.length) &&
      assetOptions.length
    ) {
      const selectedAssetIdsWithSiteId = getPersistedFilter('assetId');
      if (selectedAssetIdsWithSiteId) {
        dispatchFilterState({
          assets: assetOptions.filter(({ id }) =>
            selectedAssetIdsWithSiteId.some(i => i.split(':')[0] === id)
          ),
        });
      } else if (lockedAssetIds?.length) {
        dispatchFilterState({
          assets: assetOptions.filter(({ id }) => lockedAssetIds.some(i => i === id)),
        });
      }
    }
  }, [persistedStateRestored, selectedKeys, lockedAssetIds, assetOptions]);

  // Restore saved component type(s)
  useEffect(() => {
    if (!persistedStateRestored && selectedKeys.length && componentTypeOptions.length) {
      const selectedComponentTypes = getPersistedFilter('componentType');
      if (selectedComponentTypes) {
        dispatchFilterState({
          componentTypes: componentTypeOptions.filter(({ name }) =>
            selectedComponentTypes.some(t => t === snakeCase(name.toLowerCase()))
          ),
        });
      }
    }
  }, [persistedStateRestored, selectedKeys, componentTypeOptions]);

  // Restore saved component make(s)/model(s)
  useEffect(() => {
    if (!persistedStateRestored && selectedKeys.length) {
      const filterState: AssetFilterState = {};
      const selectedComponentMakes = getPersistedFilter('componentMake');
      if (selectedComponentMakes) {
        filterState.componentMakes = selectedComponentMakes;
        const selectedComponentModels = getPersistedFilter('componentModel');
        if (selectedComponentModels) {
          filterState.componentModels = selectedComponentModels;
        }
        dispatchFilterState(filterState);
      }
    }
  }, [persistedStateRestored, selectedKeys, componentMakeOptions, componentModelOptions]);

  // Restore saved component(s)
  useEffect(() => {
    if (!persistedStateRestored && selectedKeys.length && componentOptions.length) {
      const selectedComponentIdsWithSiteId = getPersistedFilter('componentId');
      if (selectedComponentIdsWithSiteId) {
        dispatchFilterState({
          components: componentOptions.filter(({ id }) =>
            selectedComponentIdsWithSiteId.some(i => i.split(':')[0] === id)
          ),
        });
      }
    }
  }, [persistedStateRestored, selectedKeys, componentOptions]);

  // If filters are cleared, reset working state in this component
  useEffect(() => {
    if (isEqual(selectedKeys, [])) {
      handleReset();
    }
  }, [selectedKeys]);

  const handleSearch = (
    searchValue: string,
    option?: {
      label?: string | JSX.Element;
      value: string;
    }
  ) => {
    if (searchValue.length > 1) {
      const re = new RegExp(searchValue, 'i');
      return typeof option?.label === 'string'
        ? !!option.label.match(re)
        : !!option?.value.match(re);
    }
    return true;
  };

  const handleReset = () => {
    const keysToReset = Object.keys(filterState).filter(k => {
      if (!!lockedAssetTypes?.length && k === 'assetTypes') {
        return false;
      }
      if (!!lockedAssetIds?.length && k === 'assets') {
        return false;
      }
      return true;
    });

    dispatchFilterState(Object.fromEntries(keysToReset.map(k => [k, undefined])));
  };

  /**
   * Change handlers for state management
   */
  const handleChangeAssetType = (typeIds: string[]) => {
    const types = assetTypeOptions.filter(({ id }) => typeIds.includes(id));
    dispatchFilterState({
      assetTypes: !!types?.length ? types : undefined,
      assetMakes: undefined,
      assetModels: undefined,
      ...(!types?.length
        ? {
            componentTypes: undefined,
            componentMakes: undefined,
            componentModels: undefined,
          }
        : {}),
    });
  };
  const handleChangeAssetMake = (makes: string[]) => {
    dispatchFilterState({
      assetMakes: !!makes?.length ? makes : undefined,
      assetModels: undefined,
    });
  };
  const handleChangeAssetModel = (models: string[]) => {
    dispatchFilterState({ assetModels: !!models.length ? models : undefined });
  };
  const handleChangeAsset = (assetIds: string[]) => {
    const assets = assetOptions.filter(({ id }) => assetIds.includes(id));
    dispatchFilterState({ assets: !!assets?.length ? assets : undefined });
  };
  const handleChangeComponentType = (typeIds: string[]) => {
    const types = componentTypeOptions.filter(({ id }) => typeIds.includes(id));
    dispatchFilterState({
      componentTypes: !!types?.length ? types : undefined,
      componentMakes: undefined,
      componentModels: undefined,
    });
  };
  const handleChangeComponentMake = (makes: string[]) => {
    dispatchFilterState({
      componentMakes: !!makes?.length ? makes : undefined,
      componentModels: undefined,
    });
  };
  const handleChangeComponentModel = (models: string[]) => {
    dispatchFilterState({ componentModels: !!models.length ? models : undefined });
  };
  const handleChangeComponent = (componentIds: string[]) => {
    const components = componentOptions.filter(({ id }) => componentIds.includes(id));
    dispatchFilterState({ components: !!components?.length ? components : undefined });
  };

  /**
   * Turn this component's state into antd table/graphql filter format
   */
  const handleSubmit = () => {
    const selectedKeys = [];

    // Locked asset types are already filtered at the top level, so we don't want to send them twice
    if (assetTypes && !lockedAssetTypes?.length) {
      selectedKeys.push({
        key: 'assetType',
        value: assetTypes.map(({ name }) => snakeCase(name.toLowerCase())),
      });

      if (assetTypes.length === 1 && assetMakes) {
        selectedKeys.push({
          key: 'assetMake',
          value: assetMakes,
        });

        if (assetModels) {
          selectedKeys.push({
            key: 'assetModel',
            value: assetModels,
          });
        }
      }
    }

    // Locked asset ids are already filtered at the top level, so we don't want to send them twice
    if (assets && !lockedAssetIds?.length) {
      selectedKeys.push({
        key: 'assetId',
        value: assets.map(({ id, ancestors }) => {
          const site = ancestors?.find(({ assetType }) => assetType?.name === 'Site');
          return `${id}:${site?.id}`;
        }),
      });
    }

    if (componentTypes) {
      selectedKeys.push({
        key: 'componentType',
        value: componentTypes.map(({ name }) => snakeCase(name.toLowerCase())),
      });

      if (componentTypes.length === 1 && componentMakes) {
        selectedKeys.push({
          key: 'componentMake',
          value: componentMakes,
        });

        if (componentModels) {
          selectedKeys.push({
            key: 'componentModel',
            value: componentModels,
          });
        }
      }
    }

    if (components) {
      selectedKeys.push({
        key: 'componentId',
        value: components.map(({ id, ancestors }) => {
          const site = ancestors?.find(({ assetType }) => assetType?.name === 'Site');
          return `${id}:${site?.id}`;
        }),
      });
    }

    setSelectedKeys(selectedKeys);
    confirm({ closeDropdown: true });
  };

  return (
    <AutoFocus elementToFocus={inputRef}>
      <CustomFilterPopover className="ant-table-filter-dropdown" width="411px">
        <AssetFilterInnerContainer>
          <AssetSelectContainer>
            <span>Asset Type:</span>
            <Select
              ref={inputRef}
              options={assetTypeOptions.map(({ id, name }) => ({ value: id, label: name }))}
              onChange={handleChangeAssetType}
              filterOption={handleSearch}
              value={assetTypes?.map(({ id }) => id)}
              loading={assetTypesLoading}
              placeholder={assetTypesLoading ? 'Loading...' : 'Select asset type'}
              disabled={!!lockedAssetTypes?.length || !!lockedAssetIds?.length}
              allowClear
              mode="multiple"
            />
          </AssetSelectContainer>
          <AssetSelectContainer selectWidth="228px">
            <span>Asset Make:</span>
            <Select
              options={assetMakeOptions}
              onChange={handleChangeAssetMake}
              filterOption={handleSearch}
              value={assetMakes}
              disabled={assetTypes?.length !== 1 || !!lockedAssetTypes?.length}
              loading={assetMakeModelDataLoading}
              placeholder={
                assetTypes?.length !== 1
                  ? undefined
                  : assetMakeModelDataLoading
                    ? 'Loading...'
                    : 'Select asset  make'
              }
              allowClear
              mode="multiple"
            />
          </AssetSelectContainer>
          <AssetSelectContainer selectWidth="228px">
            <span>Asset Model:</span>
            <Select
              options={assetModelOptions}
              onChange={handleChangeAssetModel}
              filterOption={handleSearch}
              value={assetModels}
              disabled={!assetMakes?.length || !!lockedAssetTypes?.length}
              loading={assetMakeModelDataLoading}
              placeholder={
                !assetMakes?.length
                  ? undefined
                  : assetMakeModelDataLoading
                    ? 'Loading...'
                    : 'Select asset model'
              }
              allowClear
              mode="multiple"
            />
          </AssetSelectContainer>
          <AssetSelectContainer>
            <span>Asset:</span>
            <Tooltip
              title={
                !!lockedAssetIds?.length
                  ? 'You are on the damage table for this asset'
                  : !(sitesSelected && assetTypes?.length)
                    ? `Please select ${tableId !== 'site-damage-list-table2' ? 'at least one site in the site column filter and' : ''} at least one asset type above to filter by asset`
                    : undefined
              }
            >
              <Select
                options={assetOptions.map(({ id, displayName }) => ({
                  value: id,
                  label: displayName,
                }))}
                onChange={handleChangeAsset}
                filterOption={handleSearch}
                value={assets?.map(({ id }) => id)}
                disabled={!(sitesSelected && assetTypes?.length) || !!lockedAssetIds?.length}
                loading={assetsLoading}
                placeholder={
                  !(sitesSelected && assetTypes?.length)
                    ? undefined
                    : assetsLoading
                      ? 'Loading assets...'
                      : 'Please select'
                }
                popupMatchSelectWidth={false}
                allowClear
                mode="multiple"
              />
            </Tooltip>
          </AssetSelectContainer>
          <ComponentFiltersCollapse
            style={{ marginRight: 0 }}
            items={[
              {
                key: 1,
                label: (
                  <>
                    <span>Component Filter Options</span>{' '}
                    <TooltipWithQuestionIcon
                      message={
                        'When a parent asset is selected above, its child components may be filtered'
                      }
                    />
                  </>
                ),
                children: (
                  <>
                    <AssetSelectContainer>
                      <span>Component Type:</span>
                      <Select
                        options={componentTypeOptions.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                        onChange={handleChangeComponentType}
                        filterOption={handleSearch}
                        value={componentTypes?.map(({ id }) => id)}
                        disabled={!componentTypeOptions?.length}
                        loading={assetTypesLoading}
                        placeholder={
                          !componentTypeOptions?.length
                            ? undefined
                            : assetTypesLoading
                              ? 'Loading...'
                              : 'Select component type'
                        }
                        allowClear
                        mode="multiple"
                      />
                    </AssetSelectContainer>
                    <AssetSelectContainer selectWidth="228px">
                      <span>Component Make:</span>
                      <Select
                        options={componentMakeOptions}
                        onChange={handleChangeComponentMake}
                        filterOption={handleSearch}
                        value={componentMakes}
                        disabled={componentTypes?.length !== 1}
                        loading={componentMakeModelDataLoading}
                        placeholder={
                          componentTypes?.length !== 1
                            ? undefined
                            : componentMakeModelDataLoading
                              ? 'Loading...'
                              : 'Select component make'
                        }
                        allowClear
                        mode="multiple"
                      />
                    </AssetSelectContainer>
                    <AssetSelectContainer selectWidth="228px">
                      <span>Component Model:</span>
                      <Select
                        options={componentModelOptions}
                        onChange={handleChangeComponentModel}
                        filterOption={handleSearch}
                        value={componentModels}
                        disabled={!componentMakes?.length}
                        loading={componentMakeModelDataLoading}
                        placeholder={
                          !componentMakes?.length
                            ? undefined
                            : componentMakeModelDataLoading
                              ? 'Loading...'
                              : 'Select component model'
                        }
                        allowClear
                        mode="multiple"
                      />
                    </AssetSelectContainer>
                    <AssetSelectContainer style={{ marginBottom: 0 }}>
                      <span>Component:</span>
                      <Tooltip
                        title={
                          !assets
                            ? 'Please select at least one asset above to filter by component'
                            : undefined
                        }
                      >
                        <Select
                          options={componentOptions.map(({ id, displayName }) => ({
                            value: id,
                            label: displayName,
                          }))}
                          onChange={handleChangeComponent}
                          filterOption={handleSearch}
                          value={components?.map(({ id }) => id)}
                          disabled={!assets || !componentOptions.length}
                          loading={componentsLoading}
                          placeholder={
                            !assets || !componentOptions.length
                              ? undefined
                              : componentsLoading
                                ? 'Loading components...'
                                : 'Select component'
                          }
                          allowClear
                          mode="multiple"
                        />
                      </Tooltip>
                    </AssetSelectContainer>
                  </>
                ),
              },
            ]}
          />
        </AssetFilterInnerContainer>
        <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Button
            type="link"
            size="small"
            onClick={() => {
              clearFilters && clearFilters();
              handleReset();
            }}
          >
            Reset
          </Button>
          <Button type="primary" size="small" onClick={handleSubmit}>
            OK
          </Button>
        </div>
      </CustomFilterPopover>
    </AutoFocus>
  );
};
